import React from "react";
import { Card } from "react-bootstrap";

const WhoAmI = () => {
  return (
    <Card className="mb-3B">
      <Card.Body>
        <Card.Title className="title">
          Hunting Season 2024: My Internship Journey
        </Card.Title>
      </Card.Body>
    </Card>
  );
};

export default WhoAmI;
